<template>
    <div class="box">
        <div class="box-toolbar" style="float:left;">
        <router-link
            v-if="$can('admin/user/level-create')"
            :to="{ path: '/level/create' }"
        >
            <el-button
                type="primary"
                size="medium"
            >
                新建会员等级
            </el-button>
        </router-link>
        </div>
        <el-table
            border
            height="70vh"
            v-loading="loading"
            :stripe="true"
            :data="lists"
        >
            <el-table-column
                width="90"
                class-name="text-mono"
                prop="id"
                label="Id"
            >
            </el-table-column>
            <el-table-column
                prop="name"
                label="等级名称"
            >
            </el-table-column>
            <el-table-column
                prop="integral"
                label="所需等级积分"
            >
            </el-table-column>
            <el-table-column
                prop="discount"
                label="折扣率"
            >
            </el-table-column>

            <el-table-column
                label="操作"
            >
                <template slot-scope="scope">
                    <el-dropdown trigger="click">
                        <el-button
                            plain
                            size="mini"
                            type="primary"
                        >
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-if="$can('admin/user/level-edit')"
                                @click.native="handleModelEdit(scope.row)"
                            >
                                编辑
                            </el-dropdown-item>
                            <el-dropdown-item
                                v-if="$can('admin/user/level-delete')"
                                @click.native="handleModelDelete(scope.row)"
                            >
                                删除
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <div class="bp-pages">
            <page
                :pageSize="limit"
                :count="count"
                @change="pageChange"
                @changeSize="changeSize"
            >
            </page>
        </div>
    </div>
</template>

<script>
import pagination from '@admin/mixins/PaginationMixin'
import Page from '@admin/components/Pagination'
import UserService from '@admin/services/UserService'
export default {
  name: 'LevelHome',
  data () {
    return {
      loading: true,
      function: function (param) {
        return UserService.level(param)
      },
      delete: function (param) {
        return UserService.levelDel(param)
      }
    }
  },

  components: { Page },
  mixins: [pagination]
}
</script>
<style lang='' scoped>
</style>
